import { ColDef } from 'ag-grid-community'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import DateRangeFilter from '@shared/ag-grid/pagination-table/filters/date-range-filter/DateRangeFilter'
import PaginationTable from '@shared/ag-grid/pagination-table/PaginationTable'
import { ReportSettledTransaction } from '@shared/api/src/schemas/types'
import { HasPermission, UserNotAllowed } from '@shared/components'
import {
  useFilterModel,
  useFtpPortalHubCommunication,
  useLocations,
} from '@shared/hooks'
import {
  filterAccountTypeACHValues,
  filterSettledTransactionTypesValues,
  secCodeFilterOptionsDatahub,
} from '@shared/mapping/gateway-transactions'
import { EnumServiceName } from '@shared/types'
import { formatDate, currency, DataSource, compareLabels } from '@shared/utils'

export default function SettledTransactionsACH() {
  const { t } = useTranslation()
  const { setAppBarTitle } = useFtpPortalHubCommunication()
  const { selectedLocation, setSelectedLocation } = useLocations()

  const TransactionsReportPrivs = ['v2.reports.get', 'v2.transactions.get']

  useEffect(() => {
    setAppBarTitle(
      t('common.settled-transactions-ach'),
      null,
      t('common.reporting')
    )
  }, [])

  useEffect(() => {
    const handleLocationChange = async (event) => {
      const newLocation = event.detail.location
      await setSelectedLocation(newLocation)
    }

    window.addEventListener(
      'FTP_NAVIGATOR_LOCATION_CHANGED',
      handleLocationChange
    )

    return () => {
      window.removeEventListener(
        'FTP_NAVIGATOR_LOCATION_CHANGED',
        handleLocationChange
      )
    }
  }, [])

  const datasource = useMemo(() => {
    return new DataSource(
      'settled-transactions-reports-ach',
      {
        filterVariant: 'filterBy',
      },
      {
        'filter[location_id]': selectedLocation?.id,
      }
    )
  }, [selectedLocation?.id])

  const columnDefs: ColDef<ReportSettledTransaction>[] = useMemo(
    () => [
      {
        headerName: t('common.transaction-id'),
        field: 'transaction_id',
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        filterParams: {
          filterOptions: ['contains'],
          maxNumConditions: 1,
        },
        sortable: true,
      },
      {
        headerName: t('common.date-reported'),
        field: 'date_reported_ts',
        floatingFilter: true,
        filter: DateRangeFilter,
        valueGetter: (params) => {
          const timestamp = params.data?.date_reported_ts
          return formatDate(timestamp)
        },
        filterParams: {
          type: 'past',
          forceOnlyCustom: true,
          customFormat: 'yyyy-MM-dd',
          isUTCDate: true,
        },
        sortable: true,
      },
      {
        headerName: t('common.date-settled'),
        field: 'date_settled_ts',
        floatingFilter: true,
        filter: DateRangeFilter,
        valueGetter: (params) => {
          const timestamp = params.data?.date_settled_ts
          return formatDate(timestamp)
        },
        filterParams: {
          type: 'past',
          forceOnlyCustom: true,
          customFormat: 'yyyy-MM-dd',
          isUTCDate: true,
        },
        sortable: true,
        sort: 'desc',
      },
      {
        headerName: t('common.date-deposit'),
        field: 'date_deposited_ts',
        floatingFilter: true,
        filter: DateRangeFilter,
        valueGetter: (params) => {
          const timestamp = params.data?.date_deposited_ts
          return formatDate(timestamp)
        },
        filterParams: {
          type: 'past',
          forceOnlyCustom: true,
          customFormat: 'yyyy-MM-dd',
          isUTCDate: true,
        },
        sortable: true,
      },
      {
        headerName: t('common.token-last-four'),
        field: 'account_number',
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        filterParams: {
          filterOptions: ['contains'],
          maxNumConditions: 1,
        },
        sortable: true,
      },
      {
        headerName: t('common.account-holder-name'),
        field: 'individual_name',
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        filterParams: {
          filterOptions: ['contains'],
          maxNumConditions: 1,
        },
        sortable: true,
      },
      {
        headerName: t('common.account-type'),
        field: 'account_type',
        filter: 'agSetColumnFilter',
        floatingFilter: true,
        filterParams: {
          values: filterAccountTypeACHValues.map((option) => option.value),
          valueFormatter: (params) => {
            const option = filterAccountTypeACHValues.find(
              (option) => option.value === params.value
            )
            return option ? option.label : params.value
          },
        },
        sortable: true,
      },
      {
        headerName: t('common.transaction-sec-code'),
        field: 'entry_class',
        floatingFilter: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: secCodeFilterOptionsDatahub,
          valueFormatter: (params) => {
            const option = secCodeFilterOptionsDatahub.find(
              (option) => option === params.value
            )
            return option ? option : params.value
          },
          comparator: (a, b) => {
            const aOption = secCodeFilterOptionsDatahub.find(
              (option) => option === a
            )
            const bOption = secCodeFilterOptionsDatahub.find(
              (option) => option === b
            )
            return aOption.localeCompare(bOption)
          },
        },
        sortable: true,
      },
      {
        headerName: t('common.amount-transaction'),
        field: 'amount',
        type: 'rightAligned',
        floatingFilter: true,
        filter: 'agNumberColumnFilter',
        filterParams: {
          allowedCharPattern: '\\d\\-\\.\\$\\,',
          numberParser: (value: number) => {
            return value ? value * 100 : null
          },
          filterOptions: ['equals'],
          maxNumConditions: 1,
        },
        sortable: true,
        valueGetter: (params) => currency(params.data?.amount / 100),
      },
      {
        headerName: t('common.amount-retained'),
        field: 'amount_2',
        type: 'rightAligned',
        floatingFilter: true,
        filter: 'agNumberColumnFilter',
        filterParams: {
          allowedCharPattern: '\\d\\-\\.\\$\\,',
          numberParser: (value: number) => {
            return value ? value * 100 : null
          },
          filterOptions: ['equals'],
          maxNumConditions: 1,
        },
        sortable: true,
        valueGetter: (params) => currency(params.data?.amount_2 / 100),
      },
      {
        headerName: t('common.date-transaction'),
        field: 'trxn_utc_date_ts',
        floatingFilter: true,
        filter: DateRangeFilter,
        valueGetter: (params) => {
          const timestamp = params.data?.trxn_utc_date_ts
          return formatDate(timestamp)
        },
        filterParams: {
          type: 'past',
          forceOnlyCustom: true,
          showTimePicker: true,
          isUTCDate: true,
        },
        sortable: true,
      },
      {
        headerName: t('common.transaction-type'),
        field: 'transaction_type',
        valueGetter: (params) => {
          const type = filterSettledTransactionTypesValues.find(
            (item) => item.value === params.data?.transaction_type
          )
          return type ? type.label : params.data?.transaction_type
        },
        filter: 'agSetColumnFilter',
        filterParams: {
          values: filterSettledTransactionTypesValues.map((item) => item.value),
          valueFormatter: (params) => {
            const type = filterSettledTransactionTypesValues.find(
              (item) => item.value === params.value
            )
            return type ? type.label : params.value
          },
          comparator: compareLabels(
            Object.fromEntries(
              filterSettledTransactionTypesValues.map(({ value, label }) => [
                value,
                label,
              ])
            )
          ),
        },
        floatingFilter: true,
        sortable: true,
      },
      {
        headerName: t('merchant-portal.batch-number'),
        field: 'batch_ref_no',
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        filterParams: {
          filterOptions: ['contains'],
          maxNumConditions: 1,
        },
        sortable: true,
      },
    ],
    []
  )

  const defaultColDef = useMemo(
    () => ({
      resizable: true,
    }),
    []
  )

  const paramKeys = [
    {
      queryKey: 'filter[date_settled_ts][$gte]',
      filterKey: 'date_settled_ts',
      filterType: 'greaterThanOrEqual',
      includeTime: true,
    },
  ]

  const initialFilterModel = useFilterModel({}, paramKeys)

  return (
    <section>
      <HasPermission
        allPermissions={TransactionsReportPrivs}
        unauthorizedComponent={<UserNotAllowed />}
      >
        <PaginationTable<ReportSettledTransaction>
          getRowId={(data) => data.data.transaction_id}
          columnDefs={columnDefs}
          rowModelType="serverSide"
          serverSideDatasource={datasource}
          defaultColDef={defaultColDef}
          initialFilterModel={initialFilterModel}
          showExportButton={true}
          showClearFiltersButton={true}
          serviceName={EnumServiceName.SettledTransactionsReportsAch}
          guidingId="reports-settledtransactionsach"
        />
      </HasPermission>
    </section>
  )
}
