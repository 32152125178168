import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { navigateToUrl } from 'single-spa'
import { tss } from 'tss-react/mui'

import ClearIcon from '@mui/icons-material/Clear'
import { TextField, Autocomplete, Grid, Typography } from '@mui/material'

import { api } from '@shared/api/src'
import { SearchIcon } from '@shared/icons'

interface Option {
  type: string
}

const useStyles = tss.withName('Search').create({
  searchInput: {
    width: '290px',
    padding: 0,
    height: '40px',
    marginRight: '5px !important',
    backgroundColor: 'white',
  },
  itemContainer: {
    margin: 0,
    paddingLeft: '10px',
    paddingTop: '5px',
    height: '3.25rem',
    '&:hover': {
      borderLeft: '5px solid #D64123',
    },
  },
  iconButton: {
    color: '#374151',
    position: 'absolute',
    marginLeft: '250px',
  },
  clearIcon: {
    cursor: 'pointer',
  },
})

export const Search = () => {
  let inputRef
  const { t } = useTranslation()
  const { classes } = useStyles()
  const [loading, setLoading] = useState(false)
  const [options, setOptions] = useState<Option[]>([])
  const [value, setValue] = useState<Option>(null)
  const [inputValue, setInputValue] = useState('')
  const [searchString, setSearchString] = useState('')
  const [open, setOpen] = useState(false)
  const [key, setKey] = useState(0)
  const [isSearch, setIsSearch] = useState(false)

  useEffect(() => {
    if (inputValue === '') {
      setOpen(false)
      setOptions([])
      return undefined
    }
  }, [inputValue])

  useEffect(() => {
    if (!isSearch) {
      clearInputSearch()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname])

  useEffect(() => {
    if (!value) return

    const portal = sessionStorage.getItem('portal')
    const queryString = new URLSearchParams(`keyword=${searchString}`)

    setIsSearch(true)

    const redirectUrl = `${process.env.URL_BASE_PATH}/${portal}`

    navigateToUrl(`${redirectUrl}/${value.type.toLowerCase()}?${queryString}`)

    setIsSearch(false)
  }, [value])

  const getSearch = useCallback(async (input: string) => {
    let application = []
    let merchants = []
    try {
      setLoading(true)
      ;[application, merchants] = await Promise.all([
        api.service('applications').find({
          query: {
            keyword: input,
          },
        }),
        api.service('merchants').find({
          query: {
            keyword: input,
          },
        }),
      ])
    } finally {
      setLoading(false)
    }

    const options = [
      ...(application.length > 0
        ? [
            {
              type: 'Applications',
            },
          ]
        : []),
      ...(merchants.length > 0
        ? [
            {
              type: 'Merchants',
            },
          ]
        : []),
    ]

    setOptions(options)
  }, [])

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && inputValue !== '') {
      setOpen(true)
      setSearchString(inputValue)
      getSearch(inputValue)
    }
  }

  const clearInputSearch = () => {
    setInputValue('')
    setSearchString('')
    setValue(null)
    setKey(key + 1)
  }

  const clearSearchAndNavigate = () => {
    clearInputSearch()

    if (value) {
      const portal = sessionStorage.getItem('portal')
      const redirectUrl = `${process.env.URL_BASE_PATH}/${portal}`

      navigateToUrl(`${redirectUrl}/${value?.type.toLowerCase() || ''}`)
    }
  }

  useEffect(() => {
    if (key <= 0) {
      return
    }

    inputRef.focus()
  }, [key])

  return (
    <>
      <Autocomplete<Option>
        sx={{
          '& .MuiAutocomplete-inputRoot': {
            height: '40px',
            padding: '0 0 0 10px',
          },
        }}
        key={key}
        data-testid="search"
        getOptionLabel={() => searchString}
        filterOptions={(x) => x}
        options={options}
        forcePopupIcon={false}
        clearIcon={null}
        autoComplete
        loading={loading}
        value={value}
        clearOnBlur={true}
        noOptionsText={t('ftp-app-bar.search.no-results')}
        open={open}
        onChange={(event: any, newValue: Option) => {
          setValue(newValue)
          setOpen(false)
        }}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue)
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            inputRef={(input) => {
              inputRef = input
            }}
            className={classes.searchInput}
            InputProps={{
              ...params.InputProps,
            }}
            placeholder={t('ftp-app-bar.search.search-term')}
            onClick={() => {
              if (inputValue !== '' && options.length > 0) {
                setOpen(true)
              }
            }}
          ></TextField>
        )}
        renderOption={(props, option) => {
          return (
            <li
              {...props}
              className={classes.itemContainer}
              key={option.type + 1}
            >
              <Grid
                container
                alignContent={'center'}
                sx={{
                  margin: 0,
                  padding: 0,
                }}
              >
                <Grid item xs={2}>
                  <SearchIcon fontSize="1.5rem" />
                </Grid>

                <Grid item xs={10}>
                  <Grid item xs={12}>
                    <Typography fontSize={'1rem'}>{inputValue}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography fontSize={'0.85rem'}>{option.type}</Typography>
                  </Grid>
                </Grid>
              </Grid>
            </li>
          )
        }}
        ListboxProps={{
          sx: {
            padding: 0,
          },
        }}
        onKeyPress={handleKeyPress}
        data-guiding-id="appbar-search"
      />
      {!inputValue ? (
        <SearchIcon fontSize="inherit" className={classes.iconButton} />
      ) : (
        <ClearIcon
          fontSize="small"
          onClick={() => {
            clearSearchAndNavigate()
          }}
          className={`${classes.iconButton} ${classes.clearIcon}`}
          data-guiding-id="appbar-clearsearch"
        />
      )}
    </>
  )
}
