import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import * as yup from 'yup'

import { AppBar } from '@mui/material'

import {
  api,
  MerchantAccount,
  QuickInvoice,
  QuickInvoiceSetting,
} from '@shared/api'
import { preprocessCurrencyAmount } from '@shared/api/src/utils/transactions/preprocessCurrencyAmount'
import {
  Button,
  ButtonBar,
  ButtonBarEnd,
  HasPermission,
  Loading,
  PageLayoutContainer,
  PageLayoutContainerMain,
  PageLayoutContainerSide,
  PageLayoutDivider,
} from '@shared/components'
import {
  useFtpPortalHubCommunication,
  useLocations,
  useNotification,
  useSub,
} from '@shared/hooks'
import {
  clearBlankFields,
  formatRawDate,
  processPhone,
  toFieldErrors,
} from '@shared/utils'

import { CustomerDetails } from '@/components/customer-details/CustomerDetails'

import { AdvancedSettings } from '../components/advanced-settings/AdvancedSettings'
import { InvoiceDetails } from '../components/invoice-details/InvoiceDetails'
import {
  MerchantSelector,
  QUICK_INVOICE_MERCHANT_ACCOUNT_ACH_CHANGE_EVENT,
  QUICK_INVOICE_MERCHANT_ACCOUNT_CC_CHANGE_EVENT,
} from '../components/merchant-selector/MerchantSelector'
import { OptionalSettings } from '../components/optional-settings/OptionalSettings'
import QuickInvoiceItems from '../components/quick-invoice-items/QuickInvoiceItems'
import QuickInvoiceSummary from '../quick-invoice-summary/QuickInvoiceSummary'

export const buildQuickInvoiceSchema = (t?: (string) => string) => {
  const schema = yup.object().shape(
    {
      cc_product_transaction_id: yup
        .string()
        .when('ach_product_transaction_id', {
          is: (val) => !val,
          then: (schema) =>
            schema.required(
              t?.(
                'mfe-gateway.quick-invoice.validations.merchant-account-required'
              )
            ),
        }),
      ach_product_transaction_id: yup
        .string()
        .when('cc_product_transaction_id', {
          is: (val) => !val,
          then: (schema) =>
            schema.required(
              t?.(
                'mfe-gateway.quick-invoice.validations.merchant-account-required'
              )
            ),
        }),
      title: yup.string(),
      invoice_number: yup.string(),
      due_date: yup.string(),
      expire_date: yup.string(),
      send_email: yup.boolean(),
      send_text_to_pay: yup.boolean(),
      email: yup.string().email().nullable(),
      cell_phone: yup.string().nullable(),
      contact_id: yup.string(),
      notification_on_due_date: yup.boolean(),
      notification_days_before_due_date: yup
        .number()
        .transform((value, original) => (original === '' ? undefined : value)),
      notification_days_after_due_date: yup
        .number()
        .transform((value, original) => (original === '' ? undefined : value)),
      allow_overpayment: yup.boolean(),
      allow_partial_pay: yup.boolean(),
      single_payment_min_amount: yup
        .number()
        .transform((value, original) => (original === '' ? undefined : value)),
      single_payment_max_amount: yup
        .number()
        .transform((value, original) => (original === '' ? undefined : value)),
      bank_funded_only_override: yup.boolean(),
      tags: yup.array().of(yup.string()),
      note: yup.string(),
      quick_invoice_c1: yup.string().nullable(),
      quick_invoice_c2: yup.string().nullable(),
      quick_invoice_c3: yup.string().nullable(),
      item_list: yup
        .array()
        .of(
          yup.object().shape({
            name: yup.string(),
            amount: yup.number(),
          })
        )
        .min(
          1,
          t?.('mfe-gateway.quick-invoice.validations.item-list-required')
        ),
      files: yup
        .array()

        .max(4, t?.('mfe-gateway.quick-invoice.validations.max-files')),

      attach_files_to_email: yup.boolean(),
    },
    [['cc_product_transaction_id', 'ach_product_transaction_id']]
  )
  return schema
}

const simpleSchema = buildQuickInvoiceSchema()

export type QuickInvoiceFormData = yup.InferType<typeof simpleSchema>

export default function QuickInvoiceAdd() {
  const { setAppBarTitle } = useFtpPortalHubCommunication()
  const { t } = useTranslation()
  const { selectedLocation, allMerchantAccounts } = useLocations()
  const { setNotification } = useNotification()

  const { state } = useLocation()

  const [isInitialLoading, setIsInitialLoading] = useState(true)
  const [isLoading, setIsLoading] = useState(false)

  const [defaultSettings, setDefaultSettings] = useState<QuickInvoiceSetting>()

  const [ACHMerchantAccount, setACHMerchantAccount] =
    useState<MerchantAccount | null>(null)
  const [CCMerchantAccount, setCCMerchantAccount] =
    useState<MerchantAccount | null>(null)

  useSub<typeof QUICK_INVOICE_MERCHANT_ACCOUNT_CC_CHANGE_EVENT>(
    QUICK_INVOICE_MERCHANT_ACCOUNT_CC_CHANGE_EVENT.type,
    ({ data: merchantAccount }) => setCCMerchantAccount(merchantAccount)
  )

  useSub<typeof QUICK_INVOICE_MERCHANT_ACCOUNT_ACH_CHANGE_EVENT>(
    QUICK_INVOICE_MERCHANT_ACCOUNT_ACH_CHANGE_EVENT.type,
    ({ data: merchantAccount }) => setACHMerchantAccount(merchantAccount)
  )

  const navigate = useNavigate()

  const schema = buildQuickInvoiceSchema(t)

  const methods = useForm<QuickInvoiceFormData>({
    resolver: yupResolver(schema),
    defaultValues: {
      item_list: [],
      files: [],
    },
  })

  useEffect(() => {
    setAppBarTitle(
      t('mfe-gateway.action.quick-invoice.add'),
      undefined,
      [t('common.gateway'), t('common.quick-invoice')],
      state?.backUrl || '/merchant/gateway/quick-invoice'
    )
  }, [])

  const onSubmit = async (data: QuickInvoiceFormData) => {
    try {
      setIsLoading(true)

      let payload = {
        ...data,
        expire_date: data.expire_date
          ? formatRawDate(data.expire_date, 'yyyy-MM-dd', 'UTC')
          : null,
        due_date: data.due_date
          ? formatRawDate(data.due_date, 'yyyy-MM-dd', 'UTC')
          : null,
        location_id: selectedLocation.id,
        single_payment_min_amount: data.single_payment_min_amount
          ? preprocessCurrencyAmount(data.single_payment_min_amount)
          : null,
        single_payment_max_amount: data.single_payment_max_amount
          ? preprocessCurrencyAmount(data.single_payment_max_amount)
          : null,
        cell_phone: data.cell_phone ? processPhone(data.cell_phone) : null,
        files: data.files,
      } as unknown as QuickInvoice

      payload = clearBlankFields(payload)

      payload = {
        ...payload,
        ach_product_transaction_id: payload.ach_product_transaction_id || null,
        cc_product_transaction_id: payload.cc_product_transaction_id || null,
      }

      const newQuickInvoice = await api
        .service('quick-invoices')
        .create(payload)

      setNotification({
        label: t('mfe-gateway.quick-invoice.created-successfully'),
        type: 'success',
      })

      navigate(`/merchant/gateway/quick-invoice/${newQuickInvoice.id}/view`)
    } catch (error) {
      const errorsFormatted = toFieldErrors(error)
      Object.keys(errorsFormatted).forEach(
        (key: keyof QuickInvoiceFormData) => {
          methods.setError(key, {
            type: 'manual',
            message: errorsFormatted[key],
          })
        }
      )
    } finally {
      setIsLoading(false)
    }
  }

  const getDefaultSettings = async () => {
    try {
      setIsInitialLoading(true)

      const [settings] = await api.service('quick-invoice-settings').find({
        query: {
          filter: {
            location_id: selectedLocation.id,
          },
        },
      })

      setDefaultSettings(settings)

      methods.reset({
        allow_partial_pay: settings.default_allow_partial_pay,
        notification_days_after_due_date:
          settings.default_notification_days_after_due_date,
        notification_days_before_due_date:
          settings.default_notification_days_before_due_date,
        notification_on_due_date: settings.default_notification_on_due_date,
      })
    } catch (error) {
      console.error(error)
    } finally {
      setIsInitialLoading(false)
    }
  }

  useEffect(() => {
    if (!selectedLocation?.id) return

    getDefaultSettings()
  }, [selectedLocation])

  return (
    <HasPermission permission="v2.quickinvoices.post">
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          {isInitialLoading ? (
            <Loading />
          ) : (
            <PageLayoutContainer isButtonBarAtBottom>
              <PageLayoutContainerMain>
                <MerchantSelector defaultMerchants />
                <InvoiceDetails
                  CCMerchantAccount={CCMerchantAccount}
                  ACHMerchantAccount={ACHMerchantAccount}
                />
                <PageLayoutDivider />
                <QuickInvoiceItems />
                <PageLayoutDivider />
                <OptionalSettings defaultSettings={defaultSettings} />
                <PageLayoutDivider />
                <AdvancedSettings />
              </PageLayoutContainerMain>
              <PageLayoutContainerSide>
                <CustomerDetails
                  hideWallet
                  customerRequired={false}
                  guidingId="quickinvoice-add"
                />
                <QuickInvoiceSummary />
              </PageLayoutContainerSide>

              <AppBar
                sx={{
                  bottom: 0,
                  top: 'auto',
                  position: 'fixed',
                  boxShadow: '0px -12px 79.9px 0px rgba(0, 0, 0, 0.10)',
                }}
              >
                <ButtonBar style={{ marginBottom: '0 !important' }}>
                  <ButtonBarEnd>
                    <Button
                      key={0}
                      label={t('common.cancel')}
                      color={'secondary'}
                      disabled={false}
                      onClick={() => {
                        navigate('/merchant/gateway/quick-invoice')
                      }}
                      style={{ marginRight: '10px' }}
                      testId="cancel-button"
                      guidingId="quickinvoice-add-cancel"
                    />
                    <Button
                      key={1}
                      type="submit"
                      label={t('common.save')}
                      isLoading={isLoading}
                      style={{ marginRight: '10px', width: '86px' }}
                      testId="save-button"
                      guidingId="quickinvoice-add-save"
                    />
                  </ButtonBarEnd>
                </ButtonBar>
              </AppBar>
            </PageLayoutContainer>
          )}
        </form>
      </FormProvider>
    </HasPermission>
  )
}
