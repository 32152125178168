import 'ag-grid-enterprise'
import { ColDef } from 'ag-grid-community'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import PaginationTable from '@shared/ag-grid/pagination-table/PaginationTable'
import { Application } from '@shared/api'
import { HasPermission, UserNotAllowed } from '@shared/components'
import { useEnforceLogin, useFtpPortalHubCommunication } from '@shared/hooks'
import { EnumServiceName } from '@shared/types'
import { formatDatetime, DataSource } from '@shared/utils'

import { mapAppStatus } from '@/utils/mapping'

export default function Applications() {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [search, setSearch] = useState('')

  const { setAppBarTitle } = useFtpPortalHubCommunication()
  const { user } = useEnforceLogin()

  const commonTextColumnFilterProps: ColDef<Application> = {
    floatingFilter: true,
    filter: 'agTextColumnFilter',
    filterParams: {
      defaultOption: 'contains',
      filterOptions: ['contains'],
      maxNumConditions: 1,
    },
    sortable: true,
  }

  const commonDateColumnFilterProps: ColDef<Application> = {
    floatingFilter: true,
    filter: 'agDateColumnFilter',
    filterParams: {
      defaultOption: 'equals',
      filterOptions: ['equals', 'inRange'],
      inRangeInclusive: true,
      maxNumConditions: 1,
    },
    sortable: true,
  }

  const columnDefs: ColDef<Application>[] = useMemo(
    () => [
      {
        field: 'legal_name',
        headerName: t('partner-portal.applications.legal-name'),
        valueFormatter: (params) => params.value || '-',
        ...commonTextColumnFilterProps,
      },
      {
        field: 'dba_name',
        headerName: t('partner-portal.applications.dba-name'),
        valueFormatter: (params) => params.value || '-',
        ...commonTextColumnFilterProps,
      },
      {
        field: 'email',
        headerName: t('partner-portal.applications.email'),
        valueFormatter: (params) => params.value || '-',
        ...commonTextColumnFilterProps,
      },
      {
        field: 'status_code',
        headerName: t('partner-portal.applications.status'),
        valueGetter: (params) => mapAppStatus(params.data?.status_code),
        floatingFilter: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: [1, 3, 5, 10, 15],
          valueFormatter: (params) => mapAppStatus(params.value),
          comparator: (a, b) => {
            const optionA = mapAppStatus(a)
            const optionB = mapAppStatus(b)

            return optionA.localeCompare(optionB)
          },
        },
        sortable: true,
      },
      {
        field: 'client_app_id',
        headerName: t('partner-portal.applications.client-id'),
        valueFormatter: (params) => params.value || '-',
        ...commonTextColumnFilterProps,
      },
      {
        field: 'agent_id',
        headerName: t('partner-portal.applications.agent-id'),
        valueFormatter: (params) => params.value || '-',
        ...commonTextColumnFilterProps,
      },
      {
        field: 'company_id',
        headerName: t('partner-portal.applications.company-id'),
        ...commonTextColumnFilterProps,
      },
      {
        field: 'created_ts',
        headerName: t('partner-portal.applications.created'),
        ...commonDateColumnFilterProps,
        valueFormatter: (params) =>
          params && params.data
            ? formatDatetime(params.data.created_ts, user?.tz)
            : '-',
      },
      {
        field: 'app_sent_ts',
        headerName: t('partner-portal.applications.sent'),
        ...commonDateColumnFilterProps,
        valueGetter: (params) =>
          params && params.data
            ? formatDatetime(params.data.app_sent_ts, user?.tz)
            : '-',
      },
      {
        field: 'app_completed_ts',
        headerName: t('partner-portal.applications.completed'),
        ...commonDateColumnFilterProps,
        valueGetter: (params) =>
          params && params.data
            ? formatDatetime(params.data.app_completed_ts, user?.tz)
            : '-',
      },
      {
        field: 'application_id',
        headerName: t('partner-portal.applications.app-id'),
        valueFormatter: (params) => params.value || '-',
        ...commonTextColumnFilterProps,
      },
      // {
      //   field: 'modified_ts',
      //   headerName: 'Modified',
      //   valueGetter: (params) =>
      //     params && params.data
      //       ? formatDatetime(params.data.modified_ts, user?.tz)
      //       : '-',
      // },
      // {
      //   field: 'app_started_ts',
      //   headerName: 'Started',
      //   valueGetter: (params) =>
      //     params && params.data
      //       ? formatDatetime(params.data.app_started_ts., user?.tz)
      //       : '-',
      // },
      // {
      //   field: 'app_ready_ts',
      //   headerName: 'Ready',
      //   valueGetter: (params) =>
      //     params && params.data
      //       ? formatDatetime(params.data.app_ready_ts, user?.tz)
      //       : '-',
      // },
      // {
      //   field: 'mcc_code',
      //   headerName: 'MCC Code',
      //   valueFormatter: (params) => params.value || '-',
      // },
      // {
      //   field: 'swiped_percent',
      //   headerName: 'Swiped Percent',
      //   valueFormatter: (params) => params.value + '%',
      // },
      // {
      //   field: 'keyed_percent',
      //   headerName: 'Keyed Percent',
      //   valueFormatter: (params) => params.value + '%',
      // },
      // {
      //   field: 'ecommerce_percent',
      //   headerName: 'Ecommerce Percent',
      //   valueFormatter: (params) => params.value + '%',
      // },
      // {
      //   field: 'ownership_type',
      //   headerName: 'Ownership Type',
      //   valueGetter: (params) => getOwnershipType(params.data?.ownership_type),
      //   // filter: 'agSetColumnFilter',
      //   // filterParams: {
      //   //   values: ['C', 'Gov', 'LLC', 'LLP', 'NP', 'P', 'PO', 'S', 'SP', 'TE'],
      //   //   valueFormatter: (params) => getOwnershipType(params.value),
      //   // },
      // },
      // {
      //   field: 'fed_tax_id',
      //   headerName: 'Fed Tax ID',
      //   valueFormatter: (params) => params.value || '-',
      // },
      // {
      //   field: 'cc_high_ticket',
      //   headerName: 'CC High Ticket',
      //   valueFormatter: ({ value }) => currency(value),
      // },
      // {
      //   field: 'cc_monthly_volume',
      //   headerName: 'CC Monthly Volume',
      //   valueFormatter: ({ value }) => currency(value),
      // },
      // {
      //   field: 'cc_average_ticket',
      //   headerName: 'CC Average Ticket',
      //   valueFormatter: ({ value }) => currency(value),
      // },
      // {
      //   field: 'ec_high_ticket',
      //   headerName: 'EC High Ticket',
      //   valueFormatter: ({ value }) => currency(value),
      // },
      // {
      //   field: 'ec_monthly_volume',
      //   headerName: 'EC Monthly Volume',
      //   valueFormatter: ({ value }) => currency(value),
      // },
      // {
      //   field: 'ec_average_ticket',
      //   headerName: 'EC Average Ticket',
      //   valueFormatter: ({ value }) => currency(value),
      // },
      // {
      //   field: 'website',
      //   headerName: 'Website',
      //   valueFormatter: (params) => params.value || '-',
      // },
    ],
    []
  )

  const defaultColDef = useMemo(
    () => ({
      resizable: true,
    }),
    []
  )

  useEffect(() => {
    const clearSearch = () => {
      setSearch('')
    }

    window.addEventListener('FTP_CLEAR_SEARCH_BUTTON', clearSearch)

    return () => {
      window.removeEventListener('FTP_CLEAR_SEARCH_BUTTON', clearSearch)
    }
  }, [])

  const datasource = useMemo(() => {
    return new DataSource(
      'applications',
      {
        filterVariant: 'filter',
      },
      {
        keyword: search || undefined,
      }
    )
  }, [search])

  useEffect(() => {
    if (!window.location.search) {
      setSearch('')
      return
    }

    const params = new URLSearchParams(window.location.search)
    const search = params.get('keyword')

    setSearch(search)
  }, [window.location.search])

  useEffect(() => {
    if (!search) {
      setAppBarTitle(
        t('partner-portal.applications'),
        t('partner-portal.applications.view-applications')
      )
      return
    }

    setAppBarTitle(
      `${t(
        'partner-portal.applications.applications-search-results'
      )}: ${search}`,
      t('partner-portal.applications.searching-applications')
    )
  }, [search])

  return (
    <HasPermission
      permission="v1.partner.applications.get"
      unauthorizedComponent={<UserNotAllowed />}
    >
      {/* Not needed for release
      <div className={classes.groupButton}>
        <Button style={{ backgroundColor: '#00263A' }} variant="contained">
          New Application
        </Button>
        <ToggleButtonGroup
          value={selectedFilter}
          onChange={(event, newFilter) => {
            setSelectedFilter(newFilter)
          }}
          exclusive
        >
          <ToggleButton value="drafts">Drafts</ToggleButton>
          <ToggleButton value="completed">Completed</ToggleButton>
        </ToggleButtonGroup>
      </div> */}

      <PaginationTable<Application>
        getRowId={(data) => data.data.application_id.toString()}
        rowModelType={'serverSide'}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        serverSideDatasource={datasource}
        onRowClicked={({ data }) =>
          navigate(`/partner/applications/${data.application_id}`)
        }
        serviceName={EnumServiceName.Applications}
        guidingId="applications"
      />
    </HasPermission>
  )
}
