import { IAfterGuiAttachedParams } from 'ag-grid-community'
import { CustomFilterProps, useGridFilter } from 'ag-grid-react'
import { DateTime } from 'luxon'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react/mui'

import {
  Box,
  Grid,
  List,
  ListItemButton,
  Paper,
  Typography,
} from '@mui/material'

import { Button } from '@shared/components'
import { useEnforceLogin } from '@shared/hooks'

import { DatePicker } from './DatePicker'
import { filtersAll, filtersFuture, filtersPast } from './options'

const useStyles = tss.withName('DateRangeFilter').create(() => ({
  buttonsGroup: {
    width: '100%',
  },
}))

interface Option {
  id: string
  title: string
}

export interface DateRangeFilterProps extends CustomFilterProps {
  customFormat?: string
  allowNoFilter?: boolean
  showTimePicker?: boolean
  type: 'all' | 'future' | 'past'
  forceOnlyCustom?: boolean
  isUTCDate?: boolean
}

const DateRangeFilter = ({
  model,
  onModelChange,
  customFormat,
  allowNoFilter = true,
  showTimePicker,
  type,
  forceOnlyCustom,
  isUTCDate = false,
}: DateRangeFilterProps) => {
  const { classes } = useStyles()

  const { t } = useTranslation()
  const { user } = useEnforceLogin()

  const timezone = isUTCDate ? 'UTC' : user?.tz

  const [closeFilter, setCloseFilter] = useState<() => void>()

  const [showCustomDate, setShowCustomDate] = useState(forceOnlyCustom)

  const initialDefaultDate = DateTime.now().setZone(timezone).startOf('day')
  const endDefaultDate = DateTime.now().setZone(timezone).endOf('day')

  const [startCustomDate, setStartCustomDate] = useState<DateTime | null>(
    initialDefaultDate
  )

  const [endCustomDate, setEndCustomDate] = useState<DateTime | null>(
    endDefaultDate
  )

  const [originalPosition, setOriginalPosition] = useState({
    left: '0',
    top: '0',
  })

  const [hasToApply, setHasToApply] = useState(false)

  const [currentStringFilter, setCurrentStringFilter] = useState('')

  const handleStartCustomDateChange = (date: DateTime) => {
    if (!showTimePicker) {
      setStartCustomDate(date.startOf('day'))
    } else {
      setStartCustomDate(date)
    }
  }

  const handleEndCustomDateChange = (date: DateTime) => {
    if (!showTimePicker) {
      setEndCustomDate(date.endOf('day'))
    } else {
      setEndCustomDate(date)
    }
  }

  const options = useMemo(() => {
    let options: Option[]
    switch (type) {
      case 'future':
        options = filtersFuture
        break
      case 'past':
        options = filtersPast
        break
      case 'all':
      default:
        options = filtersAll
        break
    }

    if (!allowNoFilter) {
      options = options.filter((option) => option.id !== '')
    }

    return options
  }, [type])

  const doesFilterPass = useCallback(() => {
    return true
  }, [])

  const getModelAsString = useCallback(
    (model) => {
      let label

      if (!model || !model.filter) {
        return t('common.filter-all-time')
      }

      if (typeof model.filter === 'object') {
        let startDate: DateTime
        let endDate: DateTime
        if (customFormat) {
          startDate = DateTime.fromFormat(model.filter.$gte, customFormat)
          endDate = DateTime.fromFormat(model.filter.$lte, customFormat)
        } else {
          startDate = DateTime.fromSeconds(model.filter.$gte).setZone(timezone)
          endDate = DateTime.fromSeconds(model.filter.$lte).setZone(timezone)
        }
        label = `${startDate.toFormat('yyyy/MM/dd')}`

        if (Math.floor(endDate.diff(startDate, 'day').days) > 0) {
          label = `${label} - ${endDate.toFormat('yyyy/MM/dd')}`
        }
      } else {
        const option = options.find((option) => option.id === model.filter)
        label = t(option.title)
      }

      return label
    },
    [user, customFormat, options]
  )

  const afterGuiAttached = useCallback(
    ({ hidePopup }: IAfterGuiAttachedParams) => {
      setCloseFilter(() => hidePopup)

      const element = document.querySelector('.ag-popup-child') as HTMLElement
      setOriginalPosition({
        left: element?.style.left || '0',
        top: element?.style.top || '0',
      })

      if (forceOnlyCustom) {
        setCurrentStringFilter('custom')
      }
    },
    [model]
  )

  const afterGuiDetached = useCallback(() => {
    if (!forceOnlyCustom) {
      setShowCustomDate(false)
    }
  }, [])

  useGridFilter({
    doesFilterPass,
    getModelAsString,
    afterGuiAttached,
    afterGuiDetached,
  })

  useEffect(() => {
    if (!user) {
      return
    }
    setModel()
  }, [model, user])

  const applyFilter = () => {
    let filter:
      | string
      | {
          $gte: number | string
          $lte: number | string
        } = currentStringFilter

    if (
      (showCustomDate && !forceOnlyCustom) ||
      currentStringFilter === 'custom'
    ) {
      if (customFormat) {
        filter = {
          $gte: startCustomDate.toFormat(customFormat),
          $lte: endCustomDate.toFormat(customFormat),
        }
      } else {
        filter = {
          $gte: startCustomDate.toSeconds(),
          $lte: Math.floor(endCustomDate.toSeconds()),
        }
      }
    }

    const filterObject = filter
      ? {
          typeOfSearch: 'inRange',
          filterType: 'date',
          filter,
        }
      : null

    onModelChange(filterObject)
  }

  const setModel = () => {
    if (!model || !model.filter) {
      handleClear()
      return
    }

    if (typeof model.filter === 'string') {
      setCurrentStringFilter(model.filter)
    }

    if (typeof model.filter === 'object') {
      if (customFormat) {
        setStartCustomDate(
          DateTime.fromFormat(model.filter.$gte, customFormat)
            .setZone(timezone)
            .startOf('day')
        )
        setEndCustomDate(
          DateTime.fromFormat(model.filter.$lte, customFormat)
            .setZone(timezone)
            .endOf('day')
        )
      } else {
        setStartCustomDate(
          DateTime.fromSeconds(model.filter.$gte).setZone(timezone)
        )
        setEndCustomDate(
          DateTime.fromSeconds(model.filter.$lte).setZone(timezone)
        )
      }

      setCurrentStringFilter('custom')
    }
  }

  const handleOptionClick = async (option: Option) => {
    setCurrentStringFilter(option.id)
    if (option.id === 'custom') {
      setShowCustomDate(true)
    } else {
      setStartCustomDate(initialDefaultDate)
      setEndCustomDate(endDefaultDate)
      setHasToApply(true)
    }
  }

  const handleClear = async () => {
    if (!forceOnlyCustom) {
      setShowCustomDate(false)
    }
    setCurrentStringFilter(undefined)
    setStartCustomDate(initialDefaultDate)
    setEndCustomDate(endDefaultDate)
    if (model) {
      onModelChange(null)
    }
    closeFilter?.()
  }

  const handleApply = () => {
    applyFilter()
    closeFilter?.()
  }

  // Tricky solution to get call apply after the state is updated, this prevents having to use "await" on set state function, which is a terrible practice.
  useEffect(() => {
    if (hasToApply) {
      handleApply()
      setHasToApply(false)
    }
  }, [hasToApply])

  // Check if element goes out of the screen and adjust position
  useEffect(() => {
    const element = document.querySelector('.ag-popup-child') as HTMLElement
    if (!element) {
      return
    }

    if (!showCustomDate) {
      if (originalPosition.left !== '0' || originalPosition.top !== '0') {
        element.style.left = originalPosition.left
        element.style.top = originalPosition.top
        element.style.right = ''
      }
      return
    }

    const rect = element.getBoundingClientRect()
    if (rect.right > window.innerWidth) {
      element.style.left = 'auto'
      element.style.right = '24px'
    }
  }, [showCustomDate])

  return (
    <Paper
      sx={{
        width: showCustomDate ? '680px' : '100%',
        height: '100%',
      }}
    >
      {showCustomDate ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            p: 2,
          }}
        >
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12} md={6}>
              <DatePicker
                label={t('common.date-start')}
                timezone={timezone}
                value={startCustomDate}
                onChange={handleStartCustomDateChange}
                disableFuture={type === 'past'}
                disablePast={type === 'future'}
                displayTimeSelector={showTimePicker}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <DatePicker
                label={t('common.date-end')}
                timezone={timezone}
                value={endCustomDate}
                onChange={handleEndCustomDateChange}
                minValue={startCustomDate}
                disableFuture={type === 'past'}
                disablePast={type === 'future'}
                displayTimeSelector={showTimePicker}
              />
            </Grid>
          </Grid>
        </Box>
      ) : (
        <List component="nav" aria-label="date range options">
          {options.map((option) => (
            <ListItemButton
              key={option.id}
              onClick={() => handleOptionClick(option)}
              selected={currentStringFilter === option.id}
            >
              <Typography width="100%" fontSize="13px">
                {t(option.title)}
              </Typography>
            </ListItemButton>
          ))}
        </List>
      )}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          p: 2,
          width: '100%',
          gap: 2,
        }}
      >
        {showCustomDate && !forceOnlyCustom && (
          <Button
            className={classes.buttonsGroup}
            label={t('common.back')}
            onClick={() => setShowCustomDate(false)}
            color={'secondary'}
          />
        )}

        <Button
          className={classes.buttonsGroup}
          label={t('common.clear')}
          onClick={() => handleClear()}
          color={'secondary'}
        />
        {showCustomDate && (
          <Button
            className={classes.buttonsGroup}
            label={t('common.apply')}
            onClick={handleApply}
            color={'primary'}
          />
        )}
      </Box>
    </Paper>
  )
}

export default DateRangeFilter
