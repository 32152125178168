import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react/mui'

import { Close, ExpandMore } from '@mui/icons-material'
import {
  Typography,
  List,
  ListItem,
  Button,
  Box,
  IconButton,
  DialogContent,
  DialogTitle,
  Dialog,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CircularProgress,
} from '@mui/material'

import { Recurring, Transaction } from '@shared/api/src'
import { valueToNumber } from '@shared/api/src/utils/valueToNumber'
import {
  SurchargeDisclosure,
  Transition,
  AccountType,
} from '@shared/components'
import AppTheme from '@shared/design'
import { useEnforceLogin } from '@shared/hooks'
import { recurringTypes, accountTypeLabel } from '@shared/types'
import {
  formatDate,
  formatDatetime,
  currency,
  calculateSurcharge,
} from '@shared/utils'

interface RecurringPaymentDetailsProps {
  onClose: () => void
  recurring?: Recurring
  productTransaction?: Recurring['product_transaction']
  tz: string
  isModalOpen?: boolean
  screen?: string
}

const useStyles = tss
  .withName('RecurringPaymentDetails')
  .create(({ theme }) => ({
    root: {
      position: 'absolute',
      minWidth: '381px',
      width: 'auto',
      maxWidth: '440px',
      height: '100%',
      boxSizing: 'border-box',
    },
    listContainer: {
      overflowY: 'auto',
      flexGrow: 1,
      marginLeft: '0px 10px',
    },
    buttonsContainer: {
      borderTop: `1px solid ${theme.palette.divider}`,
      background: theme.palette.background.paper,
      marginLeft: '15px',
      marginRight: '15px',
    },
    label: {
      fontSize: '0.875rem',
      lineHeight: '1.25rem',
      color: '#4B5563',
    },
    valueLabel: {
      fontSize: '0.875rem',
      lineHeight: '1.25rem',
      color: '#111827',
    },
    amountLabel: {
      fontSize: '1.5rem',
      lineHeight: '2rem',
      fontWeight: 'bold',
    },
    divider: {
      width: '93%',
      height: '1px',
      background: theme.palette['neutral-200'],
      borderStyle: 'none',
      borderRadius: '100%',
    },
    closeButton: {
      position: 'absolute',
      right: '-20px',
    },
  }))

const RecurringPaymentDetails: React.FC<RecurringPaymentDetailsProps> = ({
  onClose,
  recurring,
  productTransaction,
  tz,
  isModalOpen = false,
}) => {
  const { classes } = useStyles(AppTheme)
  const { t } = useTranslation()
  const [surcharge, setSurcharge] = useState<number>(0)
  const [loadingSurcharge, setLoadingSurcharge] = useState(false)
  const [isCompliantSurchargeApplied, setIsCompliantSurchargeApplied] =
    useState(false)

  const handlePrintReceipt = useCallback((recurringId) => {
    const printUrl = `/print/recurring/${recurringId}`
    window.open(printUrl, '_blank', 'noreferrer')
  }, [])

  const interval =
    recurring?.interval_type === 'm'
      ? t('common.month-s')
      : recurring?.interval_type === 'w'
      ? t('common.week-s')
      : t('common.day-s')

  useEffect(() => {
    const fetchSurcharge = async () => {
      if (
        !recurring.account_vault ||
        (recurring.product_transaction?.surcharge?.compliant &&
          recurring.product_transaction?.surcharge?.state_exception_check &&
          !recurring.account_vault.billing_address.postal_code)
      ) {
        setSurcharge(0)
        return
      }

      setLoadingSurcharge(true)

      try {
        const amounts = await calculateSurcharge(
          recurring.product_transaction,
          valueToNumber(recurring.transaction_amount),
          0, // tax - will not apply with recurring
          0, // tip - will not apply with recurring
          recurring.account_vault.id
        )

        setIsCompliantSurchargeApplied(
          amounts?.surcharge_applied &&
            !!recurring.product_transaction?.surcharge?.compliant
        )

        setSurcharge(amounts?.surcharge_amount / 100)
      } catch (error) {
        console.error('Error calculating surcharge:', error)
        setIsCompliantSurchargeApplied(false)
      } finally {
        setLoadingSurcharge(false)
      }
    }

    fetchSurcharge()
  }, [])

  return (
    <>
      <Dialog
        fullScreen
        open={isModalOpen}
        onClose={onClose}
        TransitionComponent={Transition}
        PaperProps={{
          style: {
            position: 'absolute',
            minWidth: '350px',
            maxWidth: '400px',
            height: '100%',
            right: 0,
            top: 0,
            margin: 0,
            flexDirection: 'column',
          },
        }}
        aria-labelledby="transaction-details-dialog"
      >
        <DialogTitle>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            position="sticky"
            top={0}
            zIndex={1}
            bgcolor="background.paper"
            marginBottom="12px"
          >
            <Typography variant="h6" gutterBottom>
              {t('mfe-gateway.recurring-billing.payment-details')}
            </Typography>
            <IconButton
              onClick={onClose}
              className={classes.closeButton}
              aria-label="close"
              data-guiding-id="recurringbilling-paymentdetails-close"
            >
              <Close />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent
          style={{
            padding: '0',
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box className={classes.root}>
            <Box className={classes.listContainer}>
              <List>
                <ListItem>
                  <Box display="flex" flexDirection="column" width="100%">
                    <Box display="flex" justifyContent="space-between">
                      <Typography variant="subtitle1" className={classes.label}>
                        {t('common.recurring-billing.amount-per-payment')}
                      </Typography>
                      <Typography
                        variant="body2"
                        className={classes.amountLabel}
                      >
                        {loadingSurcharge ? (
                          <CircularProgress
                            style={{
                              height: '20px',
                              width: '20px',
                            }}
                          />
                        ) : (
                          currency(
                            recurring?.transaction_amount +
                              valueToNumber(surcharge)
                          )
                        )}
                      </Typography>
                    </Box>
                  </Box>
                </ListItem>

                <hr className={classes.divider}></hr>

                <ListItem>
                  <Box display="flex" flexDirection="column" width="100%">
                    {recurring?.recurring_type_id === 'i' && (
                      <Box display="flex" justifyContent="space-between" mb={2}>
                        <Typography
                          variant="subtitle1"
                          className={classes.label}
                        >
                          {t('common.recurring-billing.total-to-collect')}
                        </Typography>
                        <Typography
                          variant="body2"
                          className={classes.valueLabel}
                        >
                          {loadingSurcharge ? (
                            <CircularProgress
                              style={{
                                height: '20px',
                                width: '20px',
                              }}
                            />
                          ) : (
                            currency(
                              recurring?.installment_amount_total / 100 +
                                valueToNumber(surcharge) *
                                  recurring?.installment_total_count
                            )
                          )}
                        </Typography>
                      </Box>
                    )}
                    <Box display="flex" justifyContent="space-between">
                      <Typography variant="subtitle1" className={classes.label}>
                        {t('common.tax')}
                      </Typography>
                      <Typography
                        variant="body2"
                        className={classes.valueLabel}
                      >
                        {currency(0)}
                      </Typography>
                    </Box>
                  </Box>
                </ListItem>

                <hr className={classes.divider}></hr>

                <ListItem>
                  <Box display="flex" flexDirection="column" width="100%">
                    <Box display="flex" justifyContent="space-between" mb={2}>
                      <Typography variant="subtitle1" className={classes.label}>
                        {t('common.recurring-billing.type')}
                      </Typography>
                      <Typography
                        variant="body2"
                        className={classes.valueLabel}
                      >
                        {recurringTypes[recurring?.recurring_type_id]}
                      </Typography>
                    </Box>
                    {recurring?.recurring_type_id === 'i' && (
                      <Box display="flex" justifyContent="space-between" mb={2}>
                        <Typography
                          variant="subtitle1"
                          className={classes.label}
                        >
                          {t(
                            'mfe-gateway.recurring-billing-number-of-installments'
                          )}
                        </Typography>
                        <Typography
                          variant="body2"
                          className={classes.valueLabel}
                        >
                          {recurring?.installment_total_count}
                        </Typography>
                      </Box>
                    )}
                    <Box display="flex" justifyContent="space-between" mb={2}>
                      <Typography variant="subtitle1" className={classes.label}>
                        {t('common.recurring-billing.frequency')}
                      </Typography>
                      <Typography
                        variant="body2"
                        className={classes.valueLabel}
                      >
                        {recurring?.interval
                          ? recurring?.interval + ' ' + interval
                          : '-'}
                      </Typography>
                    </Box>
                    <Box display="flex" justifyContent="space-between" mb={2}>
                      <Typography variant="subtitle1" className={classes.label}>
                        {t('common.date-start')}
                      </Typography>
                      <Typography
                        variant="body2"
                        className={classes.valueLabel}
                      >
                        {formatDate(recurring?.start_date, 'UTC') || '-'}
                      </Typography>
                    </Box>
                    <Box display="flex" justifyContent="space-between" mb={2}>
                      <Typography variant="subtitle1" className={classes.label}>
                        {t('common.date-end')}
                      </Typography>
                      <Typography
                        variant="body2"
                        className={classes.valueLabel}
                      >
                        {formatDate(recurring?.end_date, 'UTC') || '-'}
                      </Typography>
                    </Box>
                    {recurring?.next_run_date !== '0000-00-00' && (
                      <Box display="flex" justifyContent="space-between">
                        <Typography
                          variant="subtitle1"
                          className={classes.label}
                        >
                          {t('common.date-next-run')}
                        </Typography>
                        <Typography
                          variant="body2"
                          className={classes.valueLabel}
                        >
                          {formatDate(recurring?.next_run_date, 'UTC') || '-'}
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </ListItem>

                <hr className={classes.divider}></hr>

                <ListItem>
                  <Box display="flex" flexDirection="column" width="100%">
                    <Box display="flex" justifyContent="space-between" mb={2}>
                      <Typography variant="subtitle1" className={classes.label}>
                        {t('common.customer')}
                      </Typography>
                      <Typography
                        variant="body2"
                        className={classes.valueLabel}
                      >
                        {recurring?.contact?.first_name}{' '}
                        {recurring?.contact?.last_name}
                      </Typography>
                    </Box>
                    <Box display="flex" justifyContent="space-between">
                      <Typography variant="subtitle1" className={classes.label}>
                        {`${t('common.account-type')} / ${t(
                          'common.token-last-four'
                        )}`}
                      </Typography>
                      <Box display="flex">
                        <AccountType
                          showLabel={false}
                          type={recurring?.account_vault?.account_type}
                        />
                        <Typography className={classes.valueLabel}>
                          • {recurring?.account_vault?.last_four}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </ListItem>

                <hr className={classes.divider}></hr>

                <ListItem>
                  <Box flexDirection="column" width="100%">
                    <Box display="flex" justifyContent="space-between">
                      <Typography variant="subtitle1" className={classes.label}>
                        {t('common.description')}
                      </Typography>
                      <Typography
                        variant="body2"
                        className={classes.valueLabel}
                      >
                        {recurring?.description || '-'}
                      </Typography>
                    </Box>
                  </Box>
                </ListItem>

                <ListItem>
                  <Box display="flex" flexDirection="column" width="100%">
                    <Box display="flex" justifyContent="space-between" mb={2}>
                      <Typography variant="subtitle1" className={classes.label}>
                        {`${t('common.date')} / ${t('common.time')}`}
                      </Typography>
                      <Typography
                        variant="body2"
                        className={classes.valueLabel}
                      >
                        {formatDatetime(recurring.created_ts, tz)}
                      </Typography>
                    </Box>
                    <Box display="flex" justifyContent="space-between">
                      <Typography variant="subtitle1" className={classes.label}>
                        {t('common.recurring-billing.id')}
                      </Typography>
                      <Typography
                        variant="body2"
                        className={classes.valueLabel}
                      >
                        {recurring.id}
                      </Typography>
                    </Box>
                    {isCompliantSurchargeApplied && (
                      <SurchargeDisclosure
                        merchantAccount={recurring.product_transaction}
                        containerStyle={{ marginTop: '16px' }}
                      />
                    )}
                  </Box>
                </ListItem>
              </List>

              <Accordion square={false} elevation={0}>
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                  data-guiding-id="recurringbilling-paymentdetails-moredetails"
                >
                  <Typography variant="h6" gutterBottom>
                    {t('common.more-details')}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Box
                    display="flex"
                    flexDirection="column"
                    width="100%"
                    mb={2}
                  >
                    <Typography
                      variant="subtitle1"
                      className={classes.label}
                      mb={2}
                    >
                      {t(
                        'mfe-gateway.recurring-billing.payment-schedule-original'
                      )}
                    </Typography>

                    {recurring?.payment_schedule &&
                    recurring.payment_schedule.length > 0 ? (
                      <Box
                        justifyContent="space-between"
                        alignItems="center"
                        mb={2}
                        style={{ maxHeight: '160px', overflowY: 'auto' }}
                      >
                        {recurring.payment_schedule
                          .reduce((result, value, index, array) => {
                            if (index % 2 === 0) {
                              const pair = [value, array[index + 1]].filter(
                                Boolean
                              )
                              result.push(pair)
                            }
                            return result
                          }, [])
                          .map((pair, index) => (
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                padding: '5px 0',
                              }}
                              key={index}
                            >
                              {pair.map((date, i) => (
                                <Typography
                                  key={i}
                                  variant="subtitle1"
                                  className={classes.valueLabel}
                                  noWrap
                                >
                                  {date ? formatDate(new Date(date), tz) : ''}
                                </Typography>
                              ))}
                            </div>
                          ))}
                      </Box>
                    ) : (
                      <Typography
                        variant="subtitle1"
                        className={classes.valueLabel}
                        noWrap
                      >
                        {t(
                          'mfe-gateway.recurring-billing.payment-schedule-none'
                        )}
                      </Typography>
                    )}
                  </Box>

                  <Box display="flex" flexDirection="column" width="100%">
                    <Box justifyContent="space-between">
                      <Typography
                        variant="subtitle1"
                        className={classes.label}
                        mb={2}
                      >
                        {t('payments')}
                      </Typography>
                      <Typography
                        variant="body2"
                        className={classes.valueLabel}
                        mb={2}
                      >
                        {(recurring.transactions as Transaction[]).length === 0
                          ? t('mfe-gateway.recurring-billing.no-transactions')
                          : ''}
                      </Typography>
                      {recurring.recurring_type_id === 'i' ? (
                        <Typography
                          variant="body2"
                          className={classes.valueLabel}
                          mb={2}
                        >
                          {(recurring.transactions as Transaction[]).length}{' '}
                          {t('out-of')} {recurring?.installment_total_count}
                        </Typography>
                      ) : (
                        <Typography
                          variant="body2"
                          className={classes.valueLabel}
                        >
                          {(recurring.transactions as Transaction[]).length}{' '}
                          {t(
                            'mfe-gateway.recurring-billing.payment-schedule-completed'
                          )}
                        </Typography>
                      )}
                      {recurring.recurring_type_id === 'i' ? (
                        <Typography
                          variant="body2"
                          className={classes.valueLabel}
                        >
                          {recurring?.installment_total_count -
                            (recurring.transactions as Transaction[])
                              .length}{' '}
                          {`${t(
                            'mfe-gateway.recurring-billing.payment-schedule-remaining-total'
                          )} `}
                          {currency(
                            (recurring?.installment_amount_total / 100) *
                              (recurring?.installment_total_count -
                                (recurring.transactions as Transaction[])
                                  .length)
                          )}
                        </Typography>
                      ) : null}
                    </Box>
                  </Box>
                </AccordionDetails>
              </Accordion>

              <hr className={classes.divider}></hr>

              <ListItem>
                <Box display="flex" flexDirection="column" width="100%">
                  <Box display="flex" justifyContent="space-between">
                    <Typography variant="body2" className={classes.valueLabel}>
                      {
                        productTransaction?.receipt_add_recurring_above_signature
                      }
                    </Typography>
                  </Box>
                  <hr
                    className={classes.divider}
                    style={{ marginTop: '56px', marginBottom: '4px' }}
                  />
                  <Typography
                    component="p"
                    className={classes.valueLabel}
                    style={{ fontWeight: 'medium' }}
                  >
                    {t('common.signature')}
                  </Typography>
                </Box>
              </ListItem>
            </Box>

            <Box
              className={classes.buttonsContainer}
              position="sticky"
              bottom={0}
              zIndex={1}
              gap={2}
            >
              <Button
                variant="contained"
                onClick={() => handlePrintReceipt(recurring.id)}
                color="primary"
                data-testid="print-receipt"
                fullWidth
                sx={{
                  backgroundColor: '#00263A',
                  marginBottom: '10px',
                  '&:hover': {
                    backgroundColor: '#00263A',
                    opacity: 0.8,
                  },
                }}
                data-guiding-id="recurringbilling-paymentdetails-printreceipt"
              >
                {t('mfe-gateway.action.print-receipt')}
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default RecurringPaymentDetails
