import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react/mui'

import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded'
import { Box, Typography } from '@mui/material'

import { Location, MerchantAccount } from '@shared/api'
import { api } from '@shared/api/src'
import {
  ActionModal,
  Checkbox,
  CustomMessage,
  FieldGroupContainer,
  HasPermission,
} from '@shared/components'
import { useLocations, useNotification } from '@shared/hooks'
import { toArrayFieldErrors } from '@shared/utils'

import NotificationPreference from '@/pages/settings/notifications/components/NotificationPreference'

const useStyles = tss.withName('QuickInvoiceSettings').create(({ theme }) => ({
  modal: {
    width: '65%',
    maxWidth: '700px',
    overflowX: 'hidden',
    overflowY: 'hidden',
  },
}))

interface EditQuickInvoiceSettingsProps {
  open: boolean
  handleClose: () => void
  merchantAccounts: MerchantAccount[]
  locationInfo: Location
}

export default function EditQuickInvoiceSettings({
  open,
  handleClose,
  merchantAccounts,
  locationInfo,
}: EditQuickInvoiceSettingsProps) {
  const { t } = useTranslation()
  const { classes } = useStyles()
  const { setSelectedLocation } = useLocations()
  const { setNotification } = useNotification()
  const guidingId = 'settings-location-editquickinvoicesettings'

  const allowPartialPayments = Boolean(
    locationInfo?.quick_invoice_setting?.default_allow_partial_pay
  )

  const [initialMerchantAccounts] = useState(
    merchantAccounts.map((account) => ({
      ...account,
      enabled: Boolean(account.quick_invoice_allow),
    }))
  )

  const [selectedMerchantAccounts, setSelectedMerchantAccounts] = useState(
    merchantAccounts.map((account) => ({
      ...account,
      enabled: Boolean(account.quick_invoice_allow),
    }))
  )
  const [allowPartialPaymentsState, setAllowPartialPaymentsState] =
    useState(allowPartialPayments)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setAllowPartialPaymentsState(allowPartialPayments)
  }, [allowPartialPayments])

  const hasChanges = () => {
    const merchantAccountsChanged = selectedMerchantAccounts.some(
      (account, index) =>
        account.enabled !== initialMerchantAccounts[index].enabled
    )
    const partialPaymentsChanged =
      allowPartialPaymentsState !== allowPartialPayments
    return merchantAccountsChanged || partialPaymentsChanged
  }

  const handleSave = async () => {
    setIsLoading(true)
    try {
      const updates = selectedMerchantAccounts
        .filter(
          (account, index) =>
            account.enabled !== initialMerchantAccounts[index].enabled
        )
        .map(async (account) => {
          return await api.service('merchant-accounts').patch(account.id, {
            quick_invoice_allow: account.enabled,
          })
        })

      await Promise.all(updates)

      if (allowPartialPaymentsState !== allowPartialPayments) {
        await api
          .service('quick-invoice-settings')
          .patch(locationInfo.quick_invoice_setting.id, {
            default_allow_partial_pay: allowPartialPaymentsState,
          })
      }

      setSelectedLocation({
        ...locationInfo,
        quick_invoice_setting: {
          ...locationInfo.quick_invoice_setting,
          default_allow_partial_pay: allowPartialPaymentsState,
        },
        product_transactions: selectedMerchantAccounts.map((account) => ({
          ...account,
          quick_invoice_allow: account.enabled,
        })),
      })

      setNotification({
        label: t(
          'merchant-portal.location.quick-invoice-settings-updated-successfully'
        ),
        type: 'success',
      })

      handleClose()
    } catch (error) {
      const errors = toArrayFieldErrors(error)
      const messages = errors.map((error) => error.message)
      setIsLoading(false)
      setNotification({
        type: 'error',
        label: messages.join(' | '),
      })
    } finally {
      setIsLoading(false)
    }
  }

  const toggleMerchantAccount = (id: string) => {
    setSelectedMerchantAccounts((prevState) =>
      prevState.map((account) =>
        account.id === id ? { ...account, enabled: !account.enabled } : account
      )
    )
  }

  return (
    <ActionModal
      open={open}
      title={t(
        'merchant-portal.location.quick-invoice-edit-quick-invoice-settings'
      )}
      onClose={handleClose}
      buttons={[
        {
          label: t('common.cancel'),
          color: 'secondary',
          onClick: handleClose,
          guidingId: `${guidingId}-cancel`,
        },
        {
          label: t('common.save-changes'),
          onClick: handleSave,
          color: 'primary',
          isLoading: isLoading,
          disabled: !hasChanges(),
          guidingId: `${guidingId}-save`,
        },
      ]}
      className={classes.modal}
      isScrollableContent
      guidingId={guidingId}
    >
      <Box sx={{ overflowX: 'hidden' }}>
        {locationInfo.product_transactions.some(
          (account) => account.surcharge
        ) && (
          <CustomMessage
            showIcon
            message={t(
              'merchant-portal.location.quick-invoice-cannot-be-enabled'
            )}
            IconComponent={ErrorRoundedIcon}
            iconStyle={{
              color: '#4197CB',
              background: '#E5E7EB',
            }}
          />
        )}

        <HasPermission somePermissions={['v2.producttransactiondetails.put']}>
          <FieldGroupContainer
            title={t(
              'merchant-portal.location.quick-invoice-enable-quick-per-merchant-account'
            )}
          >
            {selectedMerchantAccounts.map((account) => (
              <Checkbox
                key={account.id}
                checked={account.enabled}
                label={account.title}
                onChange={() => toggleMerchantAccount(account.id)}
                testId={`merchant-checkbox-${account.id}`}
                guidingId={`${guidingId}-merchantaccount-${account.id}`}
              />
            ))}
          </FieldGroupContainer>
        </HasPermission>

        <HasPermission somePermissions={['v2.quickinvoicesettings.put']}>
          <FieldGroupContainer
            title={t('merchant-portal.location.quick-invoice-default-settings')}
          >
            <Checkbox
              guidingId={`${guidingId}-allowpartialpay`}
              checked={allowPartialPaymentsState}
              label={t(
                'merchant-portal.location.quick-invoice-allow-partial-payments'
              )}
              description={t(
                'merchant-portal.location.quick-invoice-allow-your-customers-to-pay-partial-amount'
              )}
              onChange={() =>
                setAllowPartialPaymentsState(!allowPartialPaymentsState)
              }
            />
          </FieldGroupContainer>
        </HasPermission>
      </Box>
    </ActionModal>
  )
}
